.ValidationDateStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100vw;
    height: 100vh;

    .content {
        flex:.33;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height:100%;
        text-align: center;

        .box{
            width:70%;
            min-height:50%;
            box-sizing: border-box;
            box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
            padding:2em;
            box-sizing: border-box;

            .messageValidation {
                font-size: 2em;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                svg{
                    font-size: 2.3em;
                    margin-bottom: 0.2em;
                }
                
                &.confirmed {
                    color: $success;
                }
    
                &.cancelled {
                    color: $error;
                }
            }
    
            .event{
                background-color: #FFF;
                margin-top:1em;
                border-radius: .3em;
                padding:1em;
              
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
    
    
                .title{
                    font-size:1.4em;
                    font-weight: 100;
                    color:#d7d7d7;
                    margin-bottom:1em;
                    display: block;
                }
    
                .event__date{
                    text-align: left;
                     > div{
                        display:flex;
                        align-items: center;
                        margin:.5em 0;
                        padding:.5em 0;
                        border-bottom:solid 1px $lightGrey;
    
                        &:last-child{
                            border:none;
                        }
                        .icon{
                            width:4em;
                        }
                     }
                }
            }
        }
     
    }

    .about {
        background-color: $colorScheme6;
        padding:2em;
        box-sizing: border-box;
        flex:.66;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        font-size: 1.3em;
        line-height: 1.6em;

        img {
            width: 10em;
            margin:0 0 2em 0;
        }

        .goTo{
            margin-top:2em;
        }
    }
}
