.navigation {
    flex: 0.07;
    width: 7vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding-bottom: 2em;

    @media all and (max-width:1200px){
        flex:.1;
       width:10vw;
    }

    .app_icon{
        max-width: 50%;
        margin:-9em 0px 5em 0;

        @media all and (max-width:1450px){
            margin:-6em 0px 4em 0;
        }
        @media all and (max-width:1200px){
            margin:-2em 0px 1em 0;
        }
    }

    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            margin: 4em 0;
            border: none;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            align-items: center;

            img {
                width: 50px;
                height: 50px;
                transition: 0.3s;
            }

            svg {
                color: $midGrey;
                transition: 0.3s;
                font-size:2em;
            }

            span{
                color:$midGrey;
            }
            &:hover {
                svg {
                    color: $colorScheme3;
                }
            }
            &.active {
                svg {
                    color: $colorScheme2;
                }

                span{
                    color:$colorScheme2;
                }
            }

            @media all and (max-width: 1450px) {
                margin: 2.5em 0;
            }
        }
    }
  

    @media all and (max-width: 768px) {
        width: 5em;

        .userConnected {
            width: 2em;
            height: 2em;
        }
    }
}

.userConnected {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: $midGrey;
    border-radius: 3em;

    transition: 0.3s;

    &:after {
        position: absolute;
        display: none;
        content: "";
        width: 12px;
        height: 12px;
        background-color: $colorScheme8;
        border-radius: 30px;
        bottom: 0px;
        right: 4px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }
    &:hover {
        transform: scale(1.1);
    }
}
