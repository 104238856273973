.avatars {
    position: relative;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;

    .itemsLeft{
        margin-left:-10px;
    }


    .picture {
        width: 30px;
        height: 30px;
        border-radius: 40px;
        position: relative;
        left: -10px;
        border: solid 3px #fff;

        &:first-child {
            left: 0;
        }
    }
}

.addEvent {
    position: fixed !important;
    bottom: 3em;
    right: 2em;
    z-index: 3;
    width: 50em !important;
    max-width: 90vw;
    height: 3em !important;
    text-align: right;
    display: flex;
    flex-direction: row-reverse;

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary, 0.9);
        transition: 0.2s;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
    }

    .buttonAdd {
        display: block;
        width: 70px;
        height: 70px;
        background-color: $primary;
        border: none;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        border-radius: 2em;
        color: #fff;
        position: relative;
        z-index: 2;
        transition: 0.3s;
        border: solid 4px #fff;
        cursor: pointer;

        &:hover {
            transform: scale(1.02);
        }
    }

    .menu {
        background-color: #fff;
        width: 90%;
        border-radius: 30px 0 0 30px;
        box-shadow: -10px 10px 30px -3px rgba(0, 0, 0, 0.2);
        padding: 1em;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        position: relative;
        right: -40px;
        height: 70px;
        transition: 0.3s;
        transform: translateX(1em);
        opacity: 0;
        cursor: pointer;
        visibility: hidden;
        overflow-x: auto;

        .group {
            display: flex;
            flex-direction: row;
            border-left: solid 1px #c9c9c9;
            padding: 0 1em;

            &:first-child {
                border-left: none;
            }
        }

        button {
            background: transparent;
            margin: 0 0.2em;
            border: none;
            border-radius: 0.3em;
            transition: 0.3s;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s;

            img {
                width: 50px;
                height: 30px;
                object-fit: cover;
                transition: 0.3s;
            }

            span {
                transition: 0.3s;
                color: rgb(75, 75, 75);
            }

            &:hover {
                span {
                    color: $primary;
                }

                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &.open {
        .overlay {
            opacity: 0.8;
            visibility: visible;
        }

        .buttonAdd {
            transform: rotate(-45deg);
            &:hover {
                transform: scale(1.02) rotate(-45deg);
            }
        }

        .menu {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }
}

.modalEvent {
    position: fixed !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 9;
    top: 0;
    left: 0;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-2em);
    opacity: 0;
    visibility: hidden;

    &.open {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    .overlay {
        position: absolute;
        background-color: rgba($secondary, 0.9);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .closeModal {
        position: absolute;
        top: 1.5em;
        right: 1.5em;
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 1em;

        svg {
            transform: scale(0.8);
        }
    }

    .content {
        position: relative;
        width: 1200px;
        max-width: 90%;
        min-height: 40%;
        padding: 2em 2em 5em 2em;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
        border-radius: 0.3em;

        .row {
            .col {
                &:first-child {
                    margin-right: 0.5em;
                }

                &:last-child {
                    margin-left: 0.5em;
                }
            }

            &.date {
                margin-top: 2.3em;

                &:first-child {
                    margin-top: -.2em;
                }

                input{
                    padding:.65em !important;
                    font-size:.85em;
                }
            }
        }
        .title {
            span,
            strong {
                display: block;
            }

            span {
                color: grey;
            }
            strong {
                color: $primary;
                font-size: 2em;
            }
            text-transform: capitalize;
        }

        .add_file_button {
            margin-top: 1.25em;
        }

        .MuiAlert-root {
            position: absolute;
            bottom: 2em;
            left: 2em;
        }

        .add_event_button {
            position: absolute;
            bottom: 2em;
            right: 2em;

            svg {
                color: #fff;
                transform: scale(0.6);
            }
        }

        textarea {
            width: 100%;
            min-height: 10em;
            max-width: 100%;
            min-width: 100%;
            padding: 1em;
            box-sizing: border-box;
            border: solid 1px rgb(208, 208, 208);
            border-radius: 0.3em;
            max-height: 30em;
        }

        ul.file_list {
            list-style: none;
            padding: 0;
            max-height: 15em;
            overflow-y: auto;

            span {
                color: rgb(230, 230, 230);
                font-size: 1.2em;
                display: block;
                text-align: center;
            }
            li {
                border-bottom: solid 1px rgb(223, 223, 223);
                padding: 0.5em 0;
                color: rgb(82, 82, 82);
                font-size: 0.9em;

                button {
                    border: none;
                    background-color: #fff;
                    color: red;
                    cursor: pointer;
                    float: right;
                }
            }
        }
    }
}

.contributors_content {
    height: 90%;
    overflow: hidden;

    h3 {
        font-size: 1.5em;
        margin: 0;
        font-weight: 400;
        color: rgb(59, 59, 59);
    }

    > div {
        margin-top: 1em;
        border-radius: 0.4em 0.4em 0 0;
        overflow: hidden;
        overflow-y: hidden !important;
        background-color: #fff;
        height: 100%;
    }
    .contributors_list {
        padding: 0;
        list-style: none;
        padding-bottom: 2em;
        width: 100%;
        height: 96%;
        display: block;
        background-color: #fff;
        overflow: auto;
        margin-top: 0;

        li {
            display: flex;
            align-items: center;
            flex-direction: row;
            background-color: #fff;
            padding: 0.6em;
            margin: 0.5em 0;
            box-shadow: 4px 10px 30px -20px rgba(0, 0, 0, 0.1);

            .ico {
                position: relative;

                width: 2em;
                height: 2em;
                margin-right: 1em;
                img {
                    border-radius: 3em;
                    width: 100%;
                }
            }
            .infos {
                width: 80%;

                .function {
                    color: rgb(164, 164, 164);
                    font-style: italic;
                    font-size: 0.9em;
                    margin-top: 0.4em;
                }

                a {
                    color: $primary;
                    text-decoration: none;
                    transition: 0.3s;
                    &:hover {
                        color: rgb(45, 45, 45);
                    }
                }
            }

            .actions {
                width: 20%;
                text-align: right;
                button {
                    margin-left: 0.4em;

                    &.test {
                        color: rgb(173, 173, 173);
                        border-color: rgb(183, 183, 183);
                    }
                }
            }
        }
    }
}

/** animations */

@keyframes loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.event_actions_menu {
    position: absolute;
    top: 4em;
    right: 4em;

    .delete_action.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained):not(.MuiPickersDay-root) {
        color: $error !important;

        .MuiListItemIcon-root {
            color: $error !important;
        }
    }
}

.share_content {
    width: 600px;
    box-sizing: border-box;
    padding: 2em;
    max-width: 90vw;

    textarea {
        width: 100%;
        min-height: 10em;
        max-width: 100%;
        min-width: 100%;
        padding: 1em;
        box-sizing: border-box;
        border: solid 1px rgb(208, 208, 208);
        border-radius: 0.3em;
        max-height: 30em;
        margin: 2em 0;
    }

    .success {
        color: $success;
        display: flex;
        flex-direction: column;
        padding: 2em;
        align-items: center;
        justify-content: center;
        svg {
            transform: scale(3);
            margin-bottom: 1em;
        }
    }
}
