.header {
    background-color: #fff;
    width: 100%;
    height: 10vh;
}

.MuiButtonBase-root:not(.Mui-disabled):not(.MuiButton-contained).MuiButton-outlined.logout {
    color: $primary !important;
    border-color: $primary !important;
    border:none !important;
}