.soundPlayer{
    height:10em;
    overflow-y: auto;

    .player{
        display: flex;
        flex-direction: row;
        padding:.4em 0;
        align-items: center;
        span{
            margin-bottom:.5em;
            width:35%;
            font-size: .9em;
        }
        audio{
            width: 100%;
        }
    }
}