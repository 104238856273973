.eventDetailsContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
    background-color: rgba($secondary, 0.9) !important;

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .eventDetails {
        border-left: solid 4px #eee;
        width: 50vw;
        height: 100vh;
        background: #fff;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        opacity: 0;
        transition: 0.2s;
        transform: translateX(50%);

        .details_header {
            position: fixed;
            z-index: 9;
            background-color: #fff;
            width: 50vw;
            padding: 1em;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            border-bottom: solid 2px #eee;
            height: 8vh;

            .details_infos {
                display: flex;
                align-items: center;
                width: 70%;
                span {
                    color: rgb(201, 201, 201);
                }
                h2 {
                    margin: 0;
                    color: rgb(61, 61, 61);
                }

                input {
                    width: 100%;
                    display: inline-block;
                    padding: 0.5em;
                    border: solid 1px rgb(221, 221, 221);
                    border-radius: 0.3em;
                }

                .actions {
                    .task_actions {
                        font-size: 0.7em;
                        padding: 0.2em 0.5em !important;
                        transition: 0.2s;

                        svg {
                            font-size: 1.5em !important;
                            margin-right: 0.3em;
                            transition: 0.2s;
                        }
                        span {
                            color: rgba($secondary, 0.9) !important;
                            transition: 0.2s;
                            height: 1.6em;
                        }

                        &:hover,
                        &.done {
                            background-color: rgba($success, 0.1) !important;
                            border-color: rgba($success, 0.9) !important;
                            span,
                            svg {
                                color: $success !important;
                            }
                        }
                    }

                    .date_actions {
                        height: 2em;
                        .row {
                            .col {
                                .item {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    height: 2em;

                                    strong {
                                        font-size: 0.9em;
                                        font-weight: 400;
                                        margin-right: 0.4em;
                                    }

                                    .select_field {
                                        margin: 0;
                                    }

                                    .colorPickerButton {
                                        width: 40px;
                                        height: 20px;
                                        border: solid 1px rgb(203, 203, 203);
                                        border-radius: 0.2em;
                                        cursor: pointer;

                                        div {
                                            height: 30px;
                                            height: 13px;
                                            border-radius: 0.2em;
                                            background-color: #000;
                                        }
                                    }

                                    .block-picker {
                                        position: absolute !important;
                                        top: 2em;
                                        left: -4em;
                                        box-shadow: 0 0 30px -3px rgba(0, 0, 0, 0.2) !important;

                                        > div {
                                            input {
                                                display: none;
                                            }
                                        }
                                    }

                                    .action {
                                        .status {
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;

                                            .label {
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                border: solid 1px $lightGrey;
                                                padding: 0.3em 1em;
                                                margin-right:1em;
                                                border-radius: .4em;
                                                color:$midGrey;

                                                span {
                                                    margin-left:.4em;
                                                    color:$midGrey;
                                                }
                                            }

                                            &.confirmed {
                                                .label {
                                                    border-color: $success;
                                                    color: $success;

                                                    span {
                                                        color: $success;
                                                    }
                                                }
                                            }

                                            &.cancelled{
                                                .label {
                                                    border-color: $error;
                                                    color: $error;

                                                    span {
                                                        color: $error;
                                                    }
                                                }
                                            }

                                            button {
                                                font-size: 0.9em !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .file_actions,
                    .date_actions {
                        .textField {
                            margin: 0;
                            border: none;

                            .icon {
                                width: 15%;
                            }
                        }

                        .react-select__control {
                            height: 35px;
                            min-height: 0;
                            border-radius: 0.3em;

                            .react-select__single-value {
                                margin-top: -5px;
                            }
                            .react-select__placeholder {
                                font-size: 0.8em;
                            }
                        }
                    }
                }
            }

            .details_actions {
                width: 70%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                .share_action {
                    margin-right: 2em;
                    position: relative;
                    padding: 0.2em 0.5em !important;
                    font-size: 0.8em !important;

                    svg {
                        font-size: 1.2em !important;
                        margin-right: 0.2em;
                    }
                    span {
                        height: 1.6em;
                    }

                    &:after {
                        position: absolute;
                        right: -2em;
                        content: "";
                        display: block;
                        height: 2em;
                        width: 1px;
                        background-color: #eee;
                    }
                }

                .closeButton {
                    margin-left: -1em;
                    background: transparent;
                    border: none;
                    cursor: pointer;

                    img {
                        width: 2.5em;
                    }
                }
            }

            @media all and (max-width: 991px) {
                width: 100vw;
                padding: 0.2em 1em;
            }
        }

        .infos {
            padding: 1em;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding-top: 8vh;
            height: 92vh;
            overflow-y: auto;

            .date {
                color: rgb(195, 195, 195);
                font-weight: 500;
            }

            .input_title {
                font-size: 1.5em;
                border-radius: 0.2em;
                padding: 0.2em 0;
                font-weight: 600;
                border: solid 1px transparent;
                outline: none;
                transition: 0.3s;
                margin: 0.3em 0;

                &:hover {
                    border-color: $midGrey;
                    padding: 0.2em;
                }
                &.edit {
                    border-color: $darkGrey;
                    padding: 0.2em;
                }
            }

            .contributors {
                margin: 0;
                list-style: none;
                padding: 0;

                li {
                    margin: 0.5em 0;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    position: relative;
                    z-index: 1;

                    &:focus-within {
                        z-index: 2;
                    }

                    strong {
                        display: block;
                        width: 20%;
                        font-weight: 400;
                        font-size: 0.9em;
                    }

                    .action {
                        width: 80%;
                        .css-bpeome-MuiSvgIcon-root-MuiSelect-icon,
                        .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
                            right: 0;
                        }
                        .select,
                        .select_field {
                            width: 100%;
                            position: relative;
                            z-index: 2;
                        }

                        .colorPicker {
                            position: absolute !important;
                            z-index: 99;
                            box-shadow: 0 0 26px -3px rgba(0, 0, 0, 0.2) !important;
                            top: -4em;
                            left: 4em;

                            > div:first-child {
                                top: 30% !important;
                                left: -8% !important;
                                transform: rotate(-90deg);
                                margin-left: 0 !important;
                            }

                            span {
                                margin-left: 0;
                            }
                        }
                    }

                    span {
                        margin-left: 0.3em;
                    }

                    img {
                        width: 3em;
                        height: 3em;
                    }
                }
            }
        }

        .input_title {
            position: relative;
            z-index: 2;
        }

        .event_general_details {
            position: relative;
            z-index: 1;

            &:hover {
                z-index: 3;
            }

            .row {
                position: relative;
                z-index: 1;

                .col {
                    &:first-child {
                        width: 60%;
                        flex: 0.6;
                        //margin-top: -2em;

                        .editor {
                            margin-top: -2em;
                        }
                    }
                    &:nth-child(2) {
                        width: 40%;
                        flex: 0.4;
                    }

                    .description {
                        border: solid 1px $lightGrey;
                        padding: 0.2em;
                        min-height: 7em;
                        transition: 0.3s;
                        border-radius: 0.3em;
                        cursor: pointer;

                        p,
                        span {
                            margin: 1em;
                            color: $darkGrey;
                        }
                        span {
                            display: inline-block;
                        }

                        &:hover {
                            background-color: $lightGrey;
                            padding: 0.2em;
                        }
                    }

                    .item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 1em;
                        border-bottom: solid 1px $lightGrey;
                        padding-bottom: 1em;

                        &:last-child {
                            border-bottom: none;
                        }

                        strong {
                            font-size: 0.9em;
                            font-weight: 400;
                            width: 30%;
                        }

                        .action {
                            width: 70%;
                            transition: 0.3s;
                            padding: 0.2em 0;
                            border-radius: 0.3em;
                            cursor: pointer;

                            .select_tags {
                                div {
                                    border: none;
                                    background-color: $lightGrey;

                                    span {
                                        background-color: #fff;
                                    }
                                    .rti--input {
                                        font-size: 0.9em;
                                        background-color: transparent;
                                    }
                                }
                            }

                            .empty_contributors {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                span {
                                    color: $midGrey;
                                }
                                a {
                                    text-align: right;
                                    color: $colorScheme3;
                                    text-decoration: none;
                                    font-size: 0.8em;

                                    button {
                                        transform: scale(0.85);
                                        font-size: 1em !important;
                                        padding: 0.2em 0.5em !important;

                                        svg {
                                            font-size: 1.2em !important;
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            .action {
                                background-color: $lightGrey;
                            }
                        }
                    }
                }
            }
        }

        .eventDate {
            border: solid 1px rgb(219, 219, 219);
            border-radius: 0.3em;
            padding: 1em;
            position: relative;

            /** Color Picker */

            .event_details {
                border-bottom: solid 1px rgb(229, 229, 229);
                text-align: right;
                padding-bottom: 0.3em;
                margin-bottom: 0.3em;

                .row {
                    align-items: center;
                    justify-content: flex-end;
                }

                strong {
                    font-weight: 400;
                    font-size: 0.9em;
                    color: grey;
                }
            }

            .actions {
                position: absolute;
                right: 1em;
                bottom: 1em;

                button {
                    margin-left: 0.5em;
                    background-color: transparent;
                    border-radius: 0.3em;
                }
            }
            strong {
                font-size: 1.2em;
                color: #444;
            }

            .row {
                margin-bottom: 1em;
            }

            label {
                font-weight: 400;
                font-size: 0.9em;
                margin-bottom: 0.4em;
            }

            .date {
                font-size: 2em;
                color: #222;
            }
            p {
                font-weight: 400;
                color: #444;
            }

            @media all and (max-width: 991px) {
                .actions {
                    position: relative;
                    margin-bottom: 0.4em;
                    text-align: center;
                }
            }
        }

        .content {
            padding-top: 1em;
            position: relative;
            z-index: 3;

            .fileList {
                span {
                    display: block;
                    color: grey;
                    font-size: 0.9em;
                }

                a {
                    color: $primary;
                    margin: 0.3em 0;
                    display: block;
                }
            }
        }

        .content,
        .description {
            strong,
            h3 {
                font-weight: 500;
            }

            ul {
                li {
                    p {
                        margin: 0.1em 0;
                    }
                }
            }
        }
    }

    @media all and (max-width: 1450px) {
        .eventDetails,
        .details_header {
            width: 60vw !important;
        }
    }

    @media all and (max-width: 991px) {
        .eventDetails,
        .details_header {
            width: 100%;
        }
    }
}

.event_menu_title {
    text-align: center;
    color: rgb(185, 185, 185);
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    border-bottom: solid 1px rgb(240, 240, 240);
    padding: 0 0.5em 0.5em 1em;

    svg {
        margin-right: 0.4em;
    }
}
