.Project {
    flex: 0.95;
    width: 95%;

    header {
        display: flex;
        flex-direction: row;
        height: 80px;
        align-items: center;

        .projectName {
            flex: 0.75;
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-grow: 1;
            .thumb {
                width: 2em;
                height: 2em;
                background-color: $colorScheme4;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-weight: 600;
                border-radius: 0.3em;
                margin-right: 1em;
                font-size: 1.2em;
            }

            strong {
                font-weight: 600;
                font-size: 1.4em;
                color: $secondary;
            }

            button {
                padding: 0;
                margin: 0;
                svg {
                    margin-top: 7px;
                    transition: 0.3s;
                    font-size: 1.2em !important;

                    &:hover {
                        color: $primary !important;
                    }
                }
            }
        }

        .notifications {
            flex: 0.05;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                width: 50px;
                height: 50px;
            }

            span {
                position: absolute;
                width: 20px;
                height: 20px;
                background-color: $alert;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: -25px;
                margin-top: -20px;
                border-radius: 3em;
                font-size: 0.9em;
            }
        }
        .searchBar {
            flex: 0.2;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            input {
                border: none;
                padding: 1em;
                background-color: #eee;
                border-radius: 0.4em;
                width: 100%;
            }
        }

        @media all and (max-width: 768px) {
            padding-left: 0;
            .searchBar {
                display: none;
            }

            .projectName {
                flex: 0.9;
            }
        }
    }

    > .infos {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: flex-end;

        .tabs {
            padding: 1em 1em 1em 0em;
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0;
            width: 80%;
            @media all and (max-width:1200px){
                width:100%;
            }
            li {
                margin-right: 2em;

                @media all and (max-width: 1450px) {
                    margin-right: 2em;
                }
                @media all and (max-width:1200px){
                    margin-right:1em;
                }
                a {
                    color: $third;
                    text-decoration: none;
                    border-radius: 4px;
                    padding: 4px 14px;
                    transition:.3s;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    position:relative;
                    overflow: hidden;

                    span {
                        display: inline-flex;
                        background: rgba($third,.6);
                        border-radius: 0.2em;
                        color: #fff;
                        font-size: 0.75em;
                        min-width:2em;
                        padding: 0.2em .3em;
                        box-sizing: border-box;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        margin-left:.3em;
                    }

                    &.active {
                        background-color: $third;
                        color: #FFF;

                        span {
                            background: #FFF;
                            color:$third;
                        }

                        &:hover {
                            background-color:  $third;
                            color: white;
                        }
                    }
                    &:hover {
                        background-color: #f1f1f1;
                        color: $third;
                    }
                }
            }

            @media all and (max-width: 991px) {
                li {
                    display: none;

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3),
                    &:nth-child(4) {
                        display: inline;
                    }
                }
            }

            @media all and (max-width: 991px) {
                li {
                    margin-right: 3em;
                    &:nth-child(4) {
                        display: none;
                    }
                }
            }
        }
        .actions {
            display: flex;
            flex-direction: row;
            width: 20%;
            justify-content: flex-end;
        }
    }
    > .content {
        background-color: $lightGrey;
        width: 100%;
        height: 90%;
        border-radius: 0.3em 0.3em 0 0;
        padding: 0em 2em 0 2em;
        box-sizing: border-box;
        position: relative;

        .calendar {
            h2 {
                margin: 0 0 1em 0;
                font-weight: 400;
                color: rgb(59, 59, 59);
                font-size: 1.5em;
            }

            .fc .fc-toolbar.fc-header-toolbar {
                margin: 0;
            }
        }
        .tabContent {
            padding: 2em 0;

            h3 {
                margin: 0 0 1em 0;
                font-weight: 400;
                color: rgb(59, 59, 59);
                font-size: 1.5em;
            }
            &.files {
                .list {
                    height: 80%;
                    ul {
                        list-style: none;
                        padding: 0.4em;
                        li {
                            padding: 0.6em;
                            border-bottom: solid 1px rgb(231, 231, 231);
                            display: flex;
                            align-items: center;

                            &:last-child {
                            }
                            a {
                                color: $primary;
                                width: 80%;
                                display: inline-block;
                            }

                            .date {
                                font-size: 0.9em;
                                display: inline-block;
                                width: 18%;
                                text-align: center;
                            }

                            svg {
                                color: grey;
                            }
                        }
                    }
                }
            }

            .MuiDataGrid-root {
                border: none;
                .MuiDataGrid-main {
                    .MuiDataGrid-columnHeaders {
                        border: none;
                        box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
                        .MuiDataGrid-columnHeader {
                            border: none;

                            &:focus {
                                outline: none;
                                background-color: rgba($lightGrey, 1);
                            }
                        }
                    }
                }
            }
        }

        > div {
            width: 100%;
            position: relative;
            height: 100%;
        }

        .listContainer {
            position: relative;
            height: 100%;
        }
        .gridContainer {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: thin;
            .item {
                width: 23.7%;
                margin-bottom: 2%;
                background: #fff;
                border-radius: 0.6em;
                overflow: hidden;
                box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);

                img {
                    width: 100%;
                    max-height: 14em;
                    object-fit: cover;
                }

                .content {
                    display: flex;
                    flex-direction: column;
                    padding: 1em;
                    box-sizing: border-box;

                    .topInfos {
                        height: 20%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        box-sizing: border-box;
                        padding-bottom: 0.3em;
                        border-bottom: solid 1px rgb(240, 240, 240);
                        .contributor {
                            flex: 0.1;
                            img {
                                width: 100%;
                            }
                        }
                        .type {
                            //margin: 0 0.4em;
                            font-size: 0.9em;
                            color: rgb(78, 78, 78);
                            flex: 0.5;

                            .picture {
                                color: #e38d25;
                            }

                            .date {
                                color: #54a140;
                            }

                            .sound {
                                color: #b63320;
                            }
                            .file {
                                color: #9c77b6;
                            }
                        }
                        .date {
                            flex: 0.5;
                            font-size: 0.9em;
                            color: rgb(184, 184, 184);
                            text-align: right;
                        }
                    }

                    .mainContent {
                        padding: 1em 0;
                        box-sizing: border-box;
                        cursor: pointer;

                        .sound {
                            width: 100%;
                            audio {
                                width: 100%;
                            }
                        }
                        .pictures {
                            img {
                                border-radius: 0.3em;
                            }
                            &.multiple {
                                position: relative;

                                span {
                                    position: absolute;
                                    width: 50%;
                                    display: flex;
                                    height: 50%;
                                    right: 2%;
                                    bottom: 0;
                                    background-color: rgba(255, 255, 255, 0.8);
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 1.4em;
                                }
                                img {
                                    &:first-child {
                                        width: 48%;
                                        height: 15em;
                                        object-fit: cover;
                                        margin-right: 1%;
                                    }

                                    &:nth-child(2),
                                    &:nth-child(3) {
                                        width: 48%;
                                        height: 48%;
                                        margin-left: 1%;
                                        object-fit: cover;
                                        position: absolute;
                                    }
                                    &:nth-child(3) {
                                        bottom: 2%;
                                    }
                                }

                                &.count_2 {
                                    img {
                                        &:nth-child(2) {
                                            position: relative;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }

                        .files {
                            ul {
                                list-style: none;
                                padding: 0;
                                li {
                                    border: solid 1px rgb(215, 215, 215);
                                    padding: 0.3em;
                                    border-radius: 0.4em;
                                    margin: 0.2em 0;

                                    a {
                                        color: $secondary;
                                        font-size: 0.9em;
                                    }
                                }
                            }
                        }

                        .task_content {
                            display: flex;
                            align-items: center;

                            svg {
                                color: $midGrey;
                                font-size: 1.2em !important;
                                margin-right: 0.4em;

                                &.done {
                                    color: $success;
                                }
                            }
                        }
                    }
                }

                @media all and (max-width: 1450px) {
                    width: 23.5%;
                }

                @media all and (max-width: 991px) {
                    width: 48%;
                }

                @media all and (max-width: 768px) {
                    width: 100%;
                }
            }

            .break {
                flex-basis: 100%;
                height: 0;
            }

            &:before,
            &:after {
                content: "";
                flex-basis: 100%;
                width: 0;
                order: 2;
            }
        }
        .list {
            height: 80%;
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: thin;
            position: relative;

            .loading {
                bottom: 0;
                position: fixed;
                width: 90.5%;
                height: 72%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
                background-color: rgba(255, 255, 255, 0.8);
                svg {
                    color: $midGrey;
                }
            }

            .item {
                align-items: center;
                cursor: pointer;

                &.file{
                    cursor: default;
                }
                .type {
                    font-size: 0.9em;
                }

                &:last-child {
                    margin-bottom: 3em;
                }
            }

            .content {
                display: flex;
                align-items: center;
                p {
                    font-weight: 500;
                }

                .pictures {
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        margin-left: 0.3em;
                        font-size: 1.1em;
                    }
                    img {
                        width: 4em !important;
                        height: 3em !important;
                        object-fit: cover;
                        border-radius: 0.3em;
                        border: solid 2px #fff;
                        position: relative;

                        &:nth-child(2) {
                            left: -10px;
                        }
                    }
                }
            }

            .creator {
                align-items: center;
            }
        }
        .columns {
            height: 3em;
            overflow: hidden;
            border-radius: 0.3em 0.3em 0 0;
            padding-right: 1em;
            box-sizing: border-box;
            font-weight: 500;
            position: relative;
            box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.03);
        }

        .descriptionProject {
            display: flex;
            flex-direction: row;

            .col {
                width: 50%;

                .infoContent {
                    width: 95%;
                    margin-bottom: 1em;
                    .label {
                        font-size: 0.9em;
                        color: rgb(77, 77, 77);
                        display: block;
                        margin-bottom: 0.4em;
                    }

                    h3 {
                        margin: 0;
                        font-size: 2.4em;
                        font-weight: 500;
                    }
                }

                .aboutProject {
                    background: #fff;
                    padding: 2em;
                    border-radius: 0.2em;
                    box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
                    margin-bottom: 0.5em;

                    .title {
                        display: flex;
                        align-items: center;

                        button {
                            border: none;
                            background-color: transparent;
                            padding: 0;
                            margin-left: 1em;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            svg {
                                transform: scale(0.8);
                                fill: $primary;
                            }
                        }
                        > span {
                            font-weight: 500;
                        }
                    }

                    .desc {
                        margin-top: 1em;
                        color: rgb(78, 78, 78);
                        font-size: 0.95em;
                        line-height: 1.4em;
                    }
                }
            }
        }

        .intro {
            margin: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            font-size: 1.2em;

            width: 600px;
            max-width: 95vw;

            span {
                color: $midGrey;
                margin: 0.3em 0;

                svg {
                    color: $primary;
                    position: relative;
                    top: 5px;
                }
            }

            button {
                margin: 1em auto;
                width: 50%;
            }
        }
    }

    @media all and (max-width: 991px) {
        > .content {
            .listContainer {
                overflow-x: auto;
                overflow-y: auto;
                border-radius: 0.5em;
                height: 100%;

                .list,
                .columns {
                    min-width: 1400px;
                }
            }
        }
    }

    .emptyProject {
        color: rgb(217, 217, 217);
        text-align: center;
        padding: 2em;
        display: block;
        font-size: 2em;
        font-weight: 300;
    }
}


.sharing_ics{
    background-color: rgba($lightGrey, 1);
    padding:.3em .5em;
    display: block;
    border-radius:.3em;
    display: flex;
    flex-direction: row;
    align-items: center;

    a{
        color:$primary;
        margin-left:.3em;
        flex-grow: 1;
    }

    span{
        color:$colorScheme3;
    }

    .ics_copied{
        color:$success;
        margin-right:1em;
    }
    button{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFF;
    }
    svg{
        font-size:1.4em;
        background-color: #FFF;
    }
}

.scrollable_container{
    height:78%;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 1em;
    border-radius: .3em;
    background-color: #FFF;
}
