.addContributor {
    position: absolute;
    right: 0;
}
.contributor_modal {
    .modal {
        .function_field {
            width: 96%;

            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .css-b62m3t-container {
                z-index: 2;
            }
        }

        .fullWidth {
            width: 98%;
        }

        .actions {
            margin-top: 2em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding: 1em 1.5em 0 1.5em;
            box-sizing: border-box;
            height: 3em;

            button {
                margin-left: 1em;
            }
        }
    }
}

.contractors_box {
    .col {
        position: relative;
        span {
            color: $midGrey;

            &.title {
            }

            &.alternative {
                font-size: 1.5em;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-bottom: 0.35em;

                &:before,
                &:after {
                    content: " ";
                    display: inline-block;
                    width: 2em;
                    height: 2px;
                    background-color: #eee;
                    margin: 0 0.5em;
                }
            }
        }
    }
}

.row_color {
    padding-top: 1em;
    .color_picker_box {
        flex-direction: row !important;
        align-items: center;
        padding: 0 0.5em;
        border: solid 1px #dddddd;
        height: 2.6em;
        border-radius: 0.2em;

        span {
            width: 90%;
            font-size: 0.9em;
            color: $darkGrey;
        }

        .select_field {
            width: 10% !important;
            min-width: 0 !important;
            padding-right: 0.3em;
        }
        .colorPickerButton {
            width: 40px;
            height: 25px;
            padding: 0.4em;
            box-sizing: border-box;
            border: none;
            border-radius: 0.2em;

            div {
                width: 100%;
                height: 100%;
                border-radius: 0.2em;
            }
        }
        .block-picker {
            position: absolute !important;
            top: 2em;
            left: -4em;
            box-shadow: 0 0 30px -3px rgba(0, 0, 0, 0.2) !important;

            > div {
                input {
                    display: none;
                }
            }
        }
    }
}
.contractor_thumbnail {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 40px;
    font-weight: 800;
    text-transform: uppercase;
    border: solid 2px #fff;
    position: relative;

    img {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }

    &.more {
        background-color: $secondary;
        color: #fff;
    }

    &:nth-child(2) {
        left: -10px;
    }
    &:nth-child(3) {
        left: -20px;
    }
    &:nth-child(4) {
        left: -30px;
    }
}

.contractorsFoundModal {
    position: absolute;
    background-color: #fff;
    z-index: 3;
    padding: 1em;
    width: 100%;
    height: 10em;
    overflow-x: hidden;
    top: 3em;
    top: 4em;
    box-sizing: border-box;
    box-shadow: 0px 10px 12px 0px rgba(0, 0, 0, 0.1);
}

.contractorsFound {
    max-height: 20em;
    box-shadow: 0 0 10px -3px rgba($darkGrey, 0.3);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    border-radius: 0.3e;
    z-index: 2;
    top: 6em;
    width: 201% !important;
}

.contractorsFoundModal,
.contractorsFound {
    .contractor {
        padding: 1em;
        border-bottom: solid 1px $lightGrey;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:last-child {
            border: none;
        }

        .contractorInfos {
            flex: 1;

            .row {
                .contractorName,
                .contractorCompany,
                .contractorEmail {
                    margin-right: 1em;
                    font-weight: 400;
                }
                .contractorCompany {
                    color: $colorScheme1;
                    font-weight: 600;
                }
                .contractorAddress {
                    color: $midGrey;
                }

                .contractorEmail {
                    color: $midGrey;
                }
            }
        }
    }
}
