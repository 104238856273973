.subscription {
    .row {
        .title {
            color: $darkGrey;
            font-size: 1.6em;
            font-weight: 500;

            span.actual_plan_label {
                margin-left: 0.5em;
                background-color: $primary;
                padding: 0em 1em;
                font-size: 0.8em;
                border-radius: 0.3em;
                color: #fff;
                height: 1.5em;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.row_actual_plan {
            margin-bottom: 0em;
        }

        &.row_selector_title {
            align-items: center;

            .title {
                font-size: 2em;
                color: $darkGrey;

                flex-grow: 1;
                span {
                    color: $primary;
                }
            }
            .toggle {
                button {
                    background-color: rgb(244, 244, 244);
                    color: $primary !important;
                    border: solid 2px $primary;
                    border-radius: 0;
                    padding: 0.3em 1em;

                    &:first-child {
                        border-radius: 2em 0 0 2em;
                    }

                    &:last-child {
                        border-radius: 0 2em 2em 0;
                    }
                    &.active {
                        background-color: $primary !important;
                        color: #fff !important;
                    }
                }
            }
        }

        .plan_selector {
            .plan {
                border-radius: 1em;
                overflow: hidden;

                span.plan_not_selected,
                span.actual_selected {
                    display: flex;
                    padding: 0.3em;
                    height: 2em;
                }
                span.actual_selected {
                    align-items: center;
                    justify-content: center;
                    background-color: $primary;
                    color: #fff;
                }

                .content {
                    padding: 1em;
                    text-align: center;

                    strong {
                        font-weight: 600;
                        font-size: 2em;
                    }

                    span.price {
                        margin-top: 1em;
                        font-size: 1.5em;
                        font-weight: bold;
                        display: block;
                        color: $primary;
                    }

                    span.storage {
                        font-size: 1.2em;
                        font-weight: bold;
                        margin-right: 4px
                    }

                    ul.advantages {
                        list-style: none;
                        padding: 0;

                        li {
                            padding: 0.5em 0;
                            color: rgb(84, 84, 84);
                            height: 1.6em;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.reduction {
                                color: $primary;
                            }

                            span.disabled {
                                color: rgb(193, 193, 193);
                            }
                        }
                    }

                    .select_button:not(.Mui-disabled) {
                        color: $primary !important;
                    }
                }
            }
        }
    }
}
