.filters {
    height: 5%;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;

    .title{
        width:30%;
        margin:0 !important;
    }


    button.switchBtn {
        border: none;
        background: transparent;
        cursor: pointer;
        position: relative;
        opacity: 0.4;
        img {
            width: 1.5em;
            transition: 0.3s;
        }

        .amount{ 
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            width:20px;
            height:20px;
            background-color: $primary;
            color:#FFF;
            top:-10px;
            right:-10px;
        }
        &.active {
            opacity: 1;
        }
    }
    .switch {
        width: 30%;
        button {
            margin-right: 2em;
        }

        @media all and (max-width: 768px) {
            width: 40%;
        }
    }
    .filtersActions {
        width: 70%;
        text-align: right;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .search_event {
            position: relative;
            flex-grow: 1;
            width: 100%;
            text-align: left;
            background-color: #fff;
            margin-right:3em;

            .MuiInputLabel-outlined{
                top:-8px;

                &.Mui-focused,&.MuiInputLabel-shrink{
                    top:0;
                }
            }

            input {
                padding: 0.5em !important;
                
            }   
        }
        .enter_message{
            position:absolute;
            display: flex;
            align-items: center;
            font-size:.8em;
            z-index: 1;
            right: 8em;
            color:$midGrey;
            transition:.3s;
            svg{
                margin:0 .3em;
                background:$lightGrey;
                padding:0 .3em;
                font-size:1.2em;
            }

            &.empty{
                opacity:0;
                visibility: hidden;
                z-index: -1;
            }

        }

        .filtersBox {
            position: absolute;
            background: #fff;
            padding: 2em;
            right: 0;
            min-width: 30em;
            min-height: 15em;
            z-index: -1;
            transition: 0.3s;
            opacity: 0;
            top: 3em;
            visibility: hidden;
            transform: translateY(1em);
            box-shadow: 4px 10px 30px -3px rgba(0, 0, 0, 0.1);
            border-radius: 0.3em 0 0.3em 0.3em;

            &.open {
                opacity: 1;
                visibility: visible;
                z-index: 9;
                transform: translateY(0);
            }

            .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: auto;

                h3 {
                    width: 70%;
                    margin: 0;
                }
                button {
                    width: 30%;
                    transition: 0.3s;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            .clear {
                border: none;
                background: transparent;
                cursor: pointer;
            }

            h3 {
                text-align: left;
                font-weight: 400;
                color: rgb(171, 171, 171);
                margin-top: 0;
            }

            .filterLabel {
                display: block;
                text-align: left;
                font-size: 0.9em;
                margin: 0.5em 0 1em 0;
                color: grey;
            }

            .dateFilter {
                display: flex;
                flex-direction: row;
                align-items: center;
                .picker {
                    margin: 0;

                    &.right {
                        margin-left: 1.2em !important;
                    }
                }
            }

            .type_filter{
                text-align: left;

                .input_select{
                    font-size: .8em;
                }
            }

            .select_field {
                width: 100%;
                margin: 0;

                label {
                    background-color: #fff;
                }
                div {
                    text-align: left;
                    width: 100%;
                }
            }

            .filterButton {
                margin-top: 1em;
            }
        }

        button {
            opacity: 1;
        }
    }
}
